import React from "react";
import "./About.css";
import UserIcon from "../Images/User.png";
import TransactionIcon from "../Images/Transaction.png";
import DemoForm from "../Components/DemoFrom";

const Linestyle1 = () => {
  return (
    <div>
      <div
        style={{
          height: "120px",
          width: "60px",
          display: "flex",
        }}
      >
        <div
          style={{
            height: "100%",
            width: "50%",
            borderTop: "3px dashed blue",
            borderRight: "3px dashed blue",
          }}
        ></div>
        <div
          style={{
            height: "100%",
            width: "50%",
            borderBottom: "3px dashed blue",
          }}
        ></div>
      </div>
    </div>
  );
};

const Linestyle2 = () => {
  return (
    <div>
      <div
        style={{
          height: "120px",
          width: "60px",
          display: "flex",
        }}
      >
        <div
          style={{
            height: "100%",
            width: "50%",
            borderBottom: "3px dashed blue",
            borderRight: "3px dashed blue",
          }}
        ></div>
        <div
          style={{
            height: "100%",
            width: "50%",
            borderTop: "3px dashed blue",
          }}
        ></div>
      </div>
    </div>
  );
};

function About() {
  const AboutTile = ({ title, content, Url }: any) => {
    return (
      <div className="CommonBoxType1">

        <div className="InnercardConatiner">
          <div className="commonBoxIconContainer">
            <img src={Url} className="commonBoxIcons" alt="icon" />
          </div>
          <div className="commonBoxHeading">{title}</div>
        </div>
        <p className="commonBoxPara" style={{ maxWidth: "300px" }}>
          {content}
        </p>
      </div>
    );
  };

  return (
    <div>
      <div className="demo-Form-container" id='DemoForm' style={{ paddingTop: "50px" }}>
        <DemoForm />
      </div>
      <div id="About">

        {/* ------------------------------about page 1 ------------------------------------------------ */}
        <div style={{ padding: "20px 0" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="AboutTitle">
              Keep your Parties, Stock, Transactions In-Check
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "30px",
              padding: "0 10px",
            }}
          >
            <p className="About2Content">
              Experience the effortless synchronization as all your ledgers and
              inventory stocks are automatically updated on Accosync. Say goodbye
              to carrying your PC everywhere now you can glimpse your business
              from anywhere, hassle-free.
            </p>
          </div>
          {/*  ---------------------------------------------------------------------------------------------------------- */}
          <div className="AboutContainer">
            <div style={{}} className="AboutItems">
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  margin: "20px 0 100px 0",
                }}
              >
                <AboutTile
                  title="Ledgers"
                  Url={require("../Images/Ledger.png")}
                  content=" Effortlessly track, organize, and manage your financial data
                with precision."
                />
                <div style={{ marginLeft: "20px" }}>
                  <Linestyle1 />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  margin: "50px 0 20px 0",
                }}
              >
                <AboutTile
                  title="Items"
                  Url={require("../Images/Items.png")}
                  content="Catalog, organize, and manage your inventory with ease and precision."
                />
                <div style={{ marginLeft: "20px" }}>
                  <Linestyle2 />
                </div>
              </div>
            </div>
            {/* -------------------------------------------------------------------------------------------------- */}
            <div className="AboutMiddleCard">
              <div className="AboutBox1">
                <div className="AboutTitle1" style={{ fontSize: "30px" }}>
                  Accosync
                </div>
                <div
                  style={{
                    color: "#505050",
                    textAlign: "justify",
                    fontSize: "18px",
                    margin: "20px",
                  }}
                >
                  Experience seamless updates as ledgers, reports, vouchers, and
                  transactions sync effortlessly between your mobile app and Tally
                  software, ensuring real-time accuracy and convenience.
                </div>
              </div>
              <div className="AboutAllCards">
                <div className="AboutItems1">
                  <AboutTile
                    title="Ledgers"
                    Url={require("../Images/Ledger.png")}
                    content=" Effortlessly track, organize, and manage your financial data
                with precision."
                  />
                </div>
                <div className="AboutItems1">
                  <AboutTile
                    title="Items"
                    Url={require("../Images/Items.png")}
                    content="Catalog, organize, and manage your inventory with ease and precision."
                  />
                </div>
                <div className="AboutItems1">
                  <AboutTile
                    title="Reports"
                    Url={require("../Images/Reports.png")}
                    content="Gain insightful perspectives and make informed decisions
                through comprehensive and customizable reporting tools."
                  />
                </div>
                <div className="AboutItems1">
                  <AboutTile
                    title="Transactions"
                    Url={require("../Images/Transaction.png")}
                    content="Efficiently record, track, and manage all financial activities
                with ease and accuracy."
                  />
                </div>
              </div>
            </div>

            {/* ---------- */}

            <div style={{}} className="AboutItems">
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  margin: "20px 0 100px 0",
                }}
              >
                <div style={{ marginRight: "30px" }}>
                  <Linestyle2 />
                </div>
                <AboutTile
                  title="Reports"
                  Url={require("../Images/Reports.png")}
                  content="Gain insightful perspectives and make informed decisions
                through comprehensive and customizable reporting tools."
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  margin: "50px 0 20px 0",
                }}
              >
                <div style={{ marginRight: "30px" }}>
                  <Linestyle1 />
                </div>
                <AboutTile
                  title="Transactions"
                  Url={require("../Images/Transaction.png")}
                  content="Efficiently record, track, and manage all financial activities
                with ease and accuracy."
                />
              </div>
            </div>
          </div>
          {/* ----------------- */}
        </div>
        {/* ---------------------------------------------------------about page 2----------------------------------------------- */}
        <div className="AboutContiner2">
          <div className="About2Title">
            Create Multiple users with each users having specific permissions
            ensuring security
          </div>

          <p className="About2Content">
            Establish multiple user accounts, each with custom-tailored
            permissions, reinforcing robust security measures to safeguard
            sensitive data and ensure controlled access within your system.
          </p>

          <div className="AboutCardSection">
            <div className="CommonBoxouterLayer">
              <div className="InnercardConatiner InnercardConatinermobile">
                <div className="commonBoxIconContainer">
                  <img src={UserIcon} className="commonBoxIcons" alt="icons" />
                </div>
                <div className="commonBoxHeading">User Management</div>
              </div>
              <p className="commonBoxPara">
                Take charge of user access effortlessly. Define precise
                permissions for each team member, ensuring secure data handling.
                Empower collaboration and streamline operations with tailored user
                management, fostering a safer and more efficient workspace for
                everyone.
              </p>
            </div>

            {/* ---------------------------- */}

            <div className="CommonBoxouterLayer">
              <div className="InnercardConatiner InnercardConatinermobile">
                <div className="commonBoxIconContainer">
                  <img
                    src={TransactionIcon}
                    className="commonBoxIcons"
                    alt="icons"
                  />
                </div>
                <div className="commonBoxHeading">Transactions Management</div>
              </div>
              <p className="commonBoxPara">
                Efficiently handle all financial activities in one central hub.
                Streamline transactions, manage accounts, and ensure accuracy with
                a comprehensive management system. Simplify your financial
                workflow for seamless operations and better control.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
