import axios from "axios";
import Card from "../Components/Card";
import { ipConfig } from "../Core/Ipconfig";
import "./DownloadStructure.css";
import { useEffect, useState } from "react";

function DownloadStructure() {
  const [downloadURL, setDownloadURL] = useState<any>("");
  const fetchConnectorCurrentURL = async () => {
    try {
      const connectorVersion: any = await axios.get(
        `${ipConfig}/currentConnectorVersion/getconnectorversion`
      );
      const currentConnectorDownloadURL: any =
        connectorVersion?.data?.data.downloadURL;
      setDownloadURL(currentConnectorDownloadURL);
    } catch (error) {
      console.log("Error fetching connector downloadURL:", error);
    }
  };

  useEffect(() => {
    fetchConnectorCurrentURL();
  }, []);

  return (
    <div className="StructureContainer">
      <Card
        title="Download Connector"
        content="Download our connector tool for streamlined device integration."
        url={require("../Images/connector.png")}
        isPointer={true}
        navigateTo={downloadURL}
      />
      <Card
        title="Sign Up"
        content="Sign up to unlock a world of possibilities and seamless connectivity."
        url={require("../Images/signup.png")}
      />
      <Card
        title="Sync Data"
        content="Effortlessly synchronize your data across devices for real-time accessibility and seamless collaboration."
        url={require("../Images/syncData.png")}
      />
      <Card
        title="Download Accosync app"
        content="Download Accosync and login with the same credentials."
        url={require("../Images/Accosync.png")}
        navigateTo={
          "https://play.google.com/store/apps/details?id=com.deco_tech.tallymobile"
        }
        disableArrow={true}
      />
    </div>
  );
}

export default DownloadStructure;
