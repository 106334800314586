import React from "react";
import { useLocation } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import BackgroundImage01 from "../Images/Background.png";
import logo from "../Images/Logo.png";
import mobile from "../Images/MobilePhoto.png";
const Success = () => {
  const location = useLocation();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100%",
        flexDirection: "row",
        backgroundImage: `url(${BackgroundImage01})`,
        backgroundSize: "cover",
        backgroundPosition: "center ",
      }}
    >
      <Box
        sx={{
          width: 950,
          height: 630,
          boxShadow: 3,
          display: "flex",
          flexDirection: "row",
          borderRadius: 3,
          backgroundColor: "white",
          "@media (max-width: 999px)": {
            width: 300,
          },
        }}
      >
        {/*Info box starts here*/}
        <Box
          sx={{
            backgroundColor: "#2596be",
            width: 600,
            height: 600,
            padding: 2,
            display: "flex",
            flexDirection: "column",
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
            alignItems: "center",
            justifyContent: "space-around",
            "@media (max-width: 999px)": {
              display: "none",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "white",
              borderRadius: "10px",
              justifyContent: "space-around",
            }}
          >
            <Box
              sx={{
                backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                flexDirection: "column",
                borderRadius: "10px",
                padding: "10px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#2596be",
                  borderRadius: "10px",
                  paddingBottom: "8px",
                }}
              >
                <img
                  src={logo}
                  height={80}
                  width={200}
                  alt="logo"
                  color="blue"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  color={"#2596be"}
                  fontSize={{ fontSize: 22, fontWeight: "500" }}
                >
                  Access Tally anytime,
                </Typography>
                <Typography
                  color={"#2596be"}
                  fontSize={{ fontSize: 22, fontWeight: "500" }}
                >
                  anywhere
                </Typography>
              </Box>
            </Box>
            <img src={mobile} height={300} width={280} alt="accosync mobile" />
          </Box>

          <Typography
            color={"white"}
            fontSize={20}
            fontWeight={"400"}
            fontFamily={"monospace"}
          >
            Experience unparalleled efficiency with remote accessibility,
            enabling seamless Tally management at your convenience. Effortlessly
            streamline operations from any location, anytime, ensuring a
            hassle-free and efficient workflow
          </Typography>
        </Box>
        {/*Info box ends here*/}
        {/*User created box starts here*/}
        <Box
          bgcolor="white"
          textAlign="center"
          sx={{
            width: 300,
            height: 615,
            margin: "5px",
            gap: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            flexDirection: "column",
            border: "3px solid grey",
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
            "@media (max-width: 999px)": {
              borderRadius: 3,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 2,
            }}
          >
            <img src={logo} height={50} width={150} alt="logo" />
            <Typography
              sx={{
                fontWeight: 700,
                fontFamily: "sans-serif",
                fontSize: 25,
                color: "grey",
              }}
            >
              New User Created
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              flexDirection: "column",
              width: "90%",
              gap: 1,
              padding: 1, // Adding padding for better spacing
            }}
          >
            <Typography variant="body1" fontSize={{ fontSize: 14 }}>
              Name: {location?.state?.userName}
            </Typography>
            <Typography
              variant="body1"
              fontSize={{ fontSize: 14 }}
              sx={{ whiteSpace: "nowrap" }}
            >
              Email: {location?.state?.userEmail}
            </Typography>
            <Typography variant="body1" fontSize={{ fontSize: 14 }}>
              Phone: {location?.state?.userPhone}
            </Typography>
          </Box>

          <Typography variant="h5">
            Thank you for using our services!
          </Typography>
          <Box>
            <Typography sx={{ display: "flex", alignItems: "center" }}>
              Visit
              <Link
                href="https://accosync.com/"
                target="_blank"
                rel="noopener noreferrer"
                underline="none"
                sx={{ marginX: 1, alignSelf: "center" }}
              >
                {"Accosync"}
              </Link>
              for more information.
            </Typography>
          </Box>
        </Box>
        {/*User created box ends here*/}
      </Box>
    </Box>
  );
};

export default Success;
