import React from "react";
import "../App.css";
import Navbar from "../Components/Navbar";
import Home from "./Home";
import About from "./About";
import Pricing from "./Pricing";
import Fotter from "./Fotter";
const Main = () => {
  return (
    <div className="App">
      <Navbar />
      <Home />
      <About />
      <Pricing />
      <Fotter />
    </div>
  );
};

export default Main;
