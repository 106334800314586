import React from "react";
import "./Pricing.css";
import PricingImage from "../Images/pricingImage.png";

function Pricing() {
  return (
    <div id="Pricing">
      <div>
        <div className="PricingTitle">Benefits and Pricing</div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "50px",
            padding: "0 10px",
          }}
        >
          <p className="PricingPara1">
            Discover the value-packed benefits and transparent pricing designed
            to elevate your experience and maximize your investment.
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* <div className="PricingImage"></div> */}
          <img
            src={PricingImage}
            style={{ maxWidth: "1330px", minWidth: "100px" }}
            alt="PricingImage"
          />

          {/* <div
            className="pricingContaineritems"
            style={{ backgroundColor: "#8BAEFF" }}
          >
            <p>pricing 1</p>
          </div>
          <div
            className="pricingContaineritems"
            style={{ backgroundColor: "#C0D3FF" }}
          >
            <p>pricing 2</p>
          </div>
          <div
            className="pricingContaineritems"
            style={{ backgroundColor: "#F1F6FF" }}
          >
            <p>pricing 3</p>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Pricing;
