import React, { useState } from "react";
import "./DemoFrom.css";
import axios from "axios";
import { ipConfig } from "../Core/Ipconfig";
import { CircularProgress } from "@mui/material";
import TaskAltIcon from '@mui/icons-material/TaskAlt';

const DemoForm = () => {
    const [formData, setFormData] = useState({
        name: "",
        number: "+91",
        email: "",
        companyName: "",
    });

    const [indicatore, setindicatore] = useState(false);
    const [submited, setSubmited] = useState(false);
    const [errors, setErrors] = useState({
        number: ""
    });

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        if (name === "number") {
            // Prepend "+91" to the number
            const formattedValue = value.startsWith("+91") ? value : "+91";
            setFormData((prevData) => ({
                ...prevData,
                [name]: formattedValue,
            }));

            // if (errors.number.length > 0) {
            //     if (!validatePhoneNumber(formData.number)) {
            //         setErrors({ number: "Invalid phone number" });
            //     } else {
            //         setErrors({ number: "" })
            //     }
            // }

        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const validatePhoneNumber = (phoneNumber: string) => {
        const cleanedNumber = phoneNumber.replace(/\s+/g, '').trim();

        // Check if the number starts with "+91" and is followed by exactly 10 digits
        const phoneRegex = /^\+91\d{10}$/;
        return phoneRegex.test(cleanedNumber) && cleanedNumber.length === 13;
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setindicatore(true);

        // Validate phone number
        if (!validatePhoneNumber(formData.number)) {
            setErrors({ number: "Invalid phone number" });
            setindicatore(false);
            return;
        }

        setErrors({ number: "" });

        // Make API request
        try {
            const response = await axios.post(`${ipConfig}/saveenquiry`, formData);

            console.log("Form submitted successfully", response.data);
            if (response?.data) {
                setSubmited(true);
                setFormData({
                    name: "",
                    number: "",
                    email: "",
                    companyName: "",
                });
            }
        } catch (error) {
            // Handle errors
            console.error("Form submission error:", error);
        }
        setindicatore(false);
    };

    return (
        <div className="form-container">
            {submited ? (
                <div style={{ minHeight: "350px", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", gap: "30px" }}>
                    <TaskAltIcon style={{ width: "100px", height: "100px", color: "green" }} />
                    <div style={{ fontWeight: "500", textAlign: "center" }}>
                        Thank you for requesting a demo for Accosync. We shall schedule a demo for you shortly.
                    </div>
                </div>
            ) : indicatore ? (
                <div style={{ minHeight: "350px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <CircularProgress />
                </div>
            ) : (
                <div style={{ minHeight: "350px" }}>
                    <div className="form-heading">Request a demo</div>
                    <form className="form-innercontainer" onSubmit={handleSubmit}>
                        <input
                            type="text"
                            name="name"
                            placeholder="Name*"
                            value={formData.name}
                            onChange={handleChange}
                            className={`demo-form-input`}
                            required
                        />
                        <input
                            type="text"
                            name="number"
                            placeholder="Contact*"
                            value={formData.number}
                            onChange={handleChange}
                            className={`demo-form-input ${errors.number ? 'error' : ''}`}
                            required
                        />
                        {errors?.number && <div className="error-message">{errors.number}</div>}
                        <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                            className={`demo-form-input`}
                        />
                        <input
                            type="text"
                            name="companyName"
                            placeholder="Company"
                            value={formData.companyName}
                            onChange={handleChange}
                            className="demo-form-input"
                        />
                        <button type="submit" className="submit-button">
                            Submit
                        </button>
                    </form>
                </div>
            )}
        </div>
    );
};

export default DemoForm;
