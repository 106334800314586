import {
  Box,
  Button,
  Modal,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import BackgroundImage01 from "../Images/Background.png";
// import { ipProxy } from "../core/pconfig";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 200,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const Purchase = () => {
  const queryString = window.location.search;
  const [responseData, setResponseData] = useState<any>({});
  const [deviceCount, setDeviceCount] = useState<any>(0);
  const [addedDeviceCount, setAddedDeviceCount] = useState<any>(0);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [timeOfPurchase, setTimeOfPurchase] = useState<any>("");
  const urlParams: any = new URLSearchParams(queryString);
  const tallyInfo: any = JSON.parse(urlParams.get("tallyInfo")) || {
    licenseNumber: "748605987",
    adminMailId: "creator18794@gmail.com",
    isEducationalMode: "No",
    planName: "Gold",
    applicationPath: "C:\\Program Files\\TallyPrime",
    dataPath: "D:\\tally",
    userLevel: "Owner",
    tallyversion:
      "TallyPrime - Release 3.0, Build - 22747 (64-bit)(3-0-0-22747)",
  };
  console.log(timeOfPurchase, "time of purchase");
  const userEmail =
    JSON.parse(urlParams.get("userData")) || "rahulkumarsahu@gmail.com";
  // "suraj@gmail.com";

  console.log(tallyInfo, "tallyInfo");
  console.log(userEmail, "userData");

  const apidata = async () => {
    try {
      const checkLicenseStatusResponse = await axios.get(`
    http://localhost:5000/user/checklicensestatus?email=${userEmail}&licensenumber=${tallyInfo?.licenseNumber}`);
      console.log(checkLicenseStatusResponse?.data, "response");

      if (checkLicenseStatusResponse.status) {
        setResponseData(checkLicenseStatusResponse.data);
        if (checkLicenseStatusResponse.data.haveLicense) {
          setDeviceCount(checkLicenseStatusResponse.data.existDeviceCount);
          setTimeOfPurchase(checkLicenseStatusResponse.data.timeOfPurchase);
        } else {
        }
      }
    } catch (error) {
      console.log(error, "error while checking license status.");
    }
  };

  useEffect(() => {
    apidata();
  });

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100%",
        flexDirection: "row",
        backgroundImage: `url(${BackgroundImage01})`,
        backgroundSize: "cover",
        backgroundPosition: "center ",
        gap: 10,
      }}
    >
      <Box
        sx={{
          width: 750,
          maxHeight: 800,
          boxShadow: 3,
          display: "flex",
          flexDirection: "column",
          borderRadius: 3,
          borderWidth: 1,
          backgroundColor: "white",
          "@media (max-width: 999px)": {
            width: 350,
          },
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "98%",
            padding: "10px",
          }}
        >
          <Typography>Purchase</Typography>

          <Button
            href="/"
            sx={{ color: "white", backgroundColor: "lightblue" }}
          >
            Back
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
            width: "90%",
            padding: "10px",
          }}
        >
          <TextField
            disabled
            variant="standard"
            id="outlined-disabled"
            label="Tally License"
            defaultValue={tallyInfo.licenseNumber}
          />
          <TextField
            disabled
            variant="standard"
            id="outlined-disabled"
            label="Registered Email"
            defaultValue={tallyInfo.adminMailId}
            sx={{ width: 250 }}
          />{" "}
        </Box>
        {responseData.haveLicense && responseData.isOwner ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "98%",
              padding: "10px",
            }}
          >
            <Typography>{responseData.message}</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
                width: "100%",
                backgroundColor: "lightgray",
                borderRadius: "5px",
              }}
            >
              <Typography color={"grey"}>
                Existing Allowed Devices :{" "}
                <span style={{ color: "black" }}>{deviceCount}</span>
              </Typography>
              <Button onClick={handleOpen}>+ ADD DEVICE</Button>
            </Box>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Added Devices :
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, fontSize: 18, color: "grey" }}
                  >
                    Added Device count :{" "}
                    <span style={{ color: "black" }}>{addedDeviceCount}</span>
                  </Typography>
                  <ToggleButtonGroup
                    color="primary"
                    value={""}
                    exclusive
                    onChange={() => console.log("Added Device")}
                    aria-label="Platform"
                    style={{ height: 30 }}
                  >
                    <ToggleButton
                      value="minus"
                      disabled={addedDeviceCount < 1}
                      onClick={() => setAddedDeviceCount(addedDeviceCount - 1)}
                    >
                      -
                    </ToggleButton>
                    <ToggleButton value="addedDeviceCount" disabled>
                      {addedDeviceCount}
                    </ToggleButton>
                    <ToggleButton
                      value="plus"
                      onClick={() => setAddedDeviceCount(addedDeviceCount + 1)}
                    >
                      +
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Typography color={"grey"}>
                    Existing Allowed Devices :{" "}
                    <span style={{ color: "black" }}>{deviceCount}</span>
                  </Typography>
                </Box>
                <Button
                  sx={{
                    backgroundColor:
                      addedDeviceCount < 1 ? "grey" : "lightblue",
                    width: "100%",
                    mt: 5,
                    "&:hover": {
                      backgroundColor: "lightblue", // Change to desired hover color
                    },
                  }}
                  disabled={addedDeviceCount < 1}
                >
                  <Typography color={"white"}>
                    ADD{" "}
                    {addedDeviceCount > 0 && <span>{addedDeviceCount}</span>}{" "}
                    NEW DEVICES
                  </Typography>
                </Button>
              </Box>
            </Modal>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "90%",
              padding: "10px",
            }}
          >
            <Typography>
              {responseData.message} {responseData.ownerEmail}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Purchase;
