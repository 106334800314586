import React from "react";
import "./Fotter.css";

function Fotter() {
  return (
    <div className="FotterContainer" id="Fotter">
      <div className="fottersections fotter1">
        <div className="fotterTitle">
          <img
            src={require("../Images/Logo.png")}
            className="NavbarLogo"
            alt="logo"
          />
        </div>
        <p className="FOttercontent">
          Experience unparalleled efficiency with remote accessibility, enabling
          seamless Tally management at your convenience. Effortlessly streamline
          operations from any location, anytime, ensuring a hassle-free and
          efficient workflow.
        </p>
        {/* <div className="FotterSocialLinks">
          <div className="FotterBox"></div>
          <div className="FotterBox"></div>
          <div className="FotterBox"></div>
          <div className="FotterBox"></div>
        </div> */}
      </div>
      <div className="fottersections lineHidder">
        <div className="FotterLine"></div>
      </div>
      <div className="fottersections ">
        <div style={{ fontSize: "30px" }}>Contact</div>
        <div className="FotterPricingSection">
          <div className="FotterAddress">
            <img
              className="FotterIcon"
              src={require("../Images/phone.png")}
              alt="icons"
            />
            <div className="FotterAddresDetail">+918093030518</div>
          </div>
          <div className="FotterAddress">
            <img
              className="FotterIcon"
              src={require("../Images/mail.png")}
              alt="icons"
            />
            <div className="FotterAddresDetail">accosync.help@gmail.com</div>
          </div>
          <div className="FotterAddress">
            <img
              className="FotterIcon"
              src={require("../Images/location.png")}
              alt="icons"
            />
            <div className="FotterAddresDetail">
              505, Esplanade Mall, Bhubaneswar, Odisha, 751010
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Fotter;
