import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Registration from "./Pages/Registration";
import Main from "./Pages/Main";
import Purchase from "./Pages/Purchase";
import Delete from "./Pages/Delete";
import Success from "./Pages/Success";
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/register" element={<Registration />} />
          <Route path="/purchase" element={<Purchase />} />
          <Route path="/success" element={<Success />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
