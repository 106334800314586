import React from "react";
import "./Home.css";
// import EastIcon from "@mui/icons-material/East";
import img from "../Images/DashBoard.png";
import DownloadStructure from "./DownloadStructure";
import DemoForm from "../Components/DemoFrom";

function Home() {
  const handleScrollNavigation = () => {
    const aboutElement = document.getElementById("DemoForm");
    if (aboutElement) {
      aboutElement.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="homeContainer" id="Home">
      <div className="HomeContents">
        <div className="HomeTopcontents">
          <div className="homeHeading">Access Tally anytime, anywhere</div>
          <div className="home-request-demo-button" onClick={() => {
            handleScrollNavigation()
          }}>Request a demo</div>
          <div className="homePara">
            Experience unparalleled efficiency with remote accessibility,
            enabling seamless Tally management at your convenience. Effortlessly
            streamline operations from any location, anytime, ensuring a
            hassle-free and efficient workflow.
          </div>
          {/* <div className="homeBtnSec">
            <button className="HomeSecBtn">Learn More</button>
            <button className="HomeSecBtn HomeSecBtnCol">
              <div>Get Started </div>
              <EastIcon fontSize="small" />
            </button>
          </div> */}
        </div>
        <div className="HomeImg">
          <img src={img} alt="Dashboard" className="PhoneImage" />
        </div>
      </div>
      <DownloadStructure />

    </div>
  );
}

export default Home;
